import { Store } from '@ngxs/store';
import { USER_KEY_LOCAL_STORAGE_KEY } from '../config/user-local-storage.constants';
import { HandleCurrentUserIdentification } from './user.actions';

// Helper for initializing the app with the user key provided in the URL or stored in local storage
// The reason for this is that the user comes to the app from an email with a key in the URL, which is used to identify the user
// TODO:AUTH: This is a temporary solution used for doing some market-fit testing, we need to implement a proper authentication flow

export function initializeApp(store: Store) {
  return () => {
    const url = new URL(window.location.href);

    let userKey = url.searchParams.get('key');
    if (!userKey) {
      const localStorageUserKey = localStorage.getItem(USER_KEY_LOCAL_STORAGE_KEY);
      userKey = localStorageUserKey || '';
    }

    if (userKey) {
      localStorage.setItem(USER_KEY_LOCAL_STORAGE_KEY, userKey);
    }

    store.dispatch(new HandleCurrentUserIdentification(userKey));
    return;
  };
}

import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import {
  Action,
  Actions,
  ofActionCompleted,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { ApplicationError } from '@troyai/error-handling';
import { catchError, tap, throwError } from 'rxjs';
import { UsersApiService } from '../user-api.service';
import { UserStateModel } from './user-state.model';
import {
  GetCurrentUserDetails,
  HandleCurrentUserIdentification,
  LoginFlowInitiated,
} from './user.actions';

@State<UserStateModel>({
  name: 'userState',
  defaults: {
    userDetails: undefined,
    isLoading: true,
    userKey: undefined,
  },
})
@Injectable()
export class UserState {
  constructor(
    private userRestApiService: UsersApiService,
    private actions$: Actions,
    private store: Store,
    private authService: AuthService
  ) {
    this.listenIfIdentified();
  }

  @Selector()
  static userDetails(state: UserStateModel) {
    return state.userDetails;
  }

  @Selector()
  static isLoading(state: UserStateModel) {
    return state.isLoading;
  }

  @Selector()
  static userKey(state: UserStateModel) {
    return state.userKey;
  }

  private listenIfIdentified(): void {
    this.actions$
      .pipe(
        ofActionCompleted(HandleCurrentUserIdentification),
        tap(() => {
          this.store.dispatch(new GetCurrentUserDetails());
        })
      )
      .subscribe();
  }

  @Action(GetCurrentUserDetails)
  getCurrentUserDetails({ patchState }: StateContext<UserStateModel>) {
    patchState({ isLoading: true });

    return this.userRestApiService.getUserDetails().pipe(
      tap((userDetails) => {
        patchState({ userDetails, isLoading: false });
      }),
      catchError(() => {
        patchState({ isLoading: false });
        return throwError(() => new ApplicationError('Failed to get current user details'));
      })
    );
  }

  @Action(HandleCurrentUserIdentification)
  handleCurrentUserIdentification(
    { patchState }: StateContext<UserStateModel>,
    { userKey }: HandleCurrentUserIdentification
  ) {
    if (!userKey) {
      return;
    }

    return patchState({ userKey });
  }

  @Action(LoginFlowInitiated)
  login(_context: StateContext<UserStateModel>, action: LoginFlowInitiated) {
    return this.authService.loginWithRedirect({
      authorizationParams: action.payload.authorizationParams,
      appState: {
        target: action.payload.redirectUrl,
        returnTo: action.payload.redirectUrl,
      },
    });
  }
}

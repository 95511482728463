import { Injectable } from '@angular/core';
import { RestApiClientService } from '@troyai/rest-api-client';
import { switchMap, timer } from 'rxjs';
import { User } from './models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService {
  constructor(private restApiService: RestApiClientService) {}

  getUserDetails() {
    return timer(500).pipe(switchMap(() => this.restApiService.get<User>('users/me')));
  }
}

import { AuthorizationParams } from '@auth0/auth0-angular';

export class GetCurrentUserDetails {
  static readonly type = '[General] Get current user details';
}

export class HandleCurrentUserIdentification {
  static readonly type = '[General] Handle current user identification';
  constructor(public userKey: string) {}
}

export class LoginFlowInitiated {
  static readonly type = '[General] Login flow initiated';
  constructor(public payload: { redirectUrl: string; authorizationParams?: AuthorizationParams }) {}
}
